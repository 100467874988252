// utils/version.js
const VERSION_KEY = 'app_version'
const VERSION_SEEN_KEY = 'version_seen'

export default {
  // 当前版本号 (每次发布新版本需要更新此值)
  currentVersion: '1.0.10',
  
  // 检查是否需要显示更新弹框
  checkVersionUpdate() {
    const savedVersion = localStorage.getItem(VERSION_KEY)
    const versionSeen = localStorage.getItem(VERSION_SEEN_KEY)
    
    // 如果是新版本且未显示过更新内容
    if (savedVersion !== this.currentVersion) {
      if (versionSeen !== this.currentVersion) {
        return true
      }
    }
    return false
  },
  
  // 标记当前版本已显示过
  markVersionAsSeen() {
    localStorage.setItem(VERSION_SEEN_KEY, this.currentVersion)
    localStorage.setItem(VERSION_KEY, this.currentVersion)
  },
  
  // 获取更新内容 (可根据版本号返回不同的更新内容)
  getUpdateContent() {
    const version = this.currentVersion
    let content = ''
    
    switch(version) {
      case '1.0.10':
        content = `
          <h4 style="margin-bottom:20px;">版本 ${version} 更新内容</h4>
          <ul>
            <li>修复设备列表加载缓慢问题</li>
          </ul>
        `
        break
      // 可以添加更多版本的更新内容
      default:
        content = `欢迎使用新版本 ${version}`
    }
    
    return content
  }
}