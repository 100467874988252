<!-- components/VersionUpdateModal.vue -->
<template>
    <transition name="modal">
      <div v-if="visible" class="modal-overlay">
        <div class="modal-container">
          <div class="modal-header">
            <h3>版本更新</h3>
            <span class="close-btn" @click="close">&times;</span>
          </div>
          <div class="modal-body" v-html="content"></div>
          <div class="modal-footer">
            <button class="btn btn-confirm" @click="close">我知道了</button>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      visible: Boolean,
      content: String
    },
    methods: {
      close() {
        this.$emit('close')
      }
    }
  }
  </script>
  
  <style scoped>
  /* 复用之前弹框的样式 */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    animation: modal-in 0.3s ease-out;
  }
  
  .modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .close-btn {
    font-size: 24px;
    cursor: pointer;
    color: #999;
  }
  
  .close-btn:hover {
    color: #333;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .modal-body >>> ul {
    padding-left: 20px;
  }
  
  .modal-body >>> li {
    margin-bottom: 8px;
  }
  
  .modal-footer {
    padding: 12px 20px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
  }
  
  .btn {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
  }
  
  .btn-confirm {
    background-color: #409eff;
    color: white;
  }
  
  .btn-confirm:hover {
    background-color: #66b1ff;
  }
  
  @keyframes modal-in {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  </style>