import { createApp } from 'vue'
import router from './router/index'

import App from './App.vue'
import ViewUIPlus from 'view-ui-plus'
import * as echarts from 'echarts'
// main.js
const env = process.env.VUE_APP_ENV; // 获取环境变量

if (env === 'sxy') {
    import('../src/assets/css/sxy.css').then(() => {
        console.log('sxy.css 加载成功');
    });
} else if (env === 'index') {
    import('../src/assets/css/index.css').then(() => {
        console.log('index.css 加载成功');
    });
} else {
    console.warn('未找到匹配的环境变量，使用默认样式');
}
// import '../src/assets/css/sxy.css'
// import '../src/assets/css/index.css'
import 'view-ui-plus/dist/styles/viewuiplus.css'

// document.addEventListener('keydown', function(e) {
//     if (e.key === 'F12' || e.keyCode === 123) {
//         e.preventDefault();
//     }
// }); 

const app = createApp(App)
// 全局挂载 echarts
app.config.globalProperties.$echarts = echarts;
app.use(router).use(ViewUIPlus).mount('#app')
