<!-- App.vue -->
<template>
  <div id="app">
    <!-- 其他内容 -->
    <router-view/>
    
    <!-- 版本更新弹框 -->
    <VersionUpdateModal
      v-if="showVersionModal"
      :visible="showVersionModal"
      :content="updateContent"
      @close="handleCloseVersionModal"
    />
  </div>
</template>

<script>
import VersionUpdateModal from '@/components/VersionUpdateModal'
import versionManager from '@/assets/utils/version'

export default {
  components: {
    VersionUpdateModal
  },
  data() {
    return {
      showVersionModal: false,
      updateContent: ''
    }
  },
  created() {
    this.checkVersionUpdate()
  },
  methods: {
    checkVersionUpdate() {
      if (versionManager.checkVersionUpdate()) {
        this.updateContent = versionManager.getUpdateContent()
        this.showVersionModal = true
      }
    },
    handleCloseVersionModal() {
      versionManager.markVersionAsSeen()
      this.showVersionModal = false
    }
  }
}
</script>